import './styles.scss';
import React, {memo, useState} from 'react';

import {Link} from "gatsby";

import {
    LoadingGif,
} from "../../images";

type Props = {
    id: number,
    arrLength: number,
    lastLink: string
}

const QuizQuestion4 = ({id, arrLength, lastLink}: Props) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    let nextStep = +id + 1;

    const linkElem = (text) => {
        if(arrLength !== id){
            return <Link to={`/quiz-career/${nextStep}`} className="quizNext"><span>{text}</span></Link>
        } else{
            return <Link to={`${lastLink}`} className="quizNext"><span>{text}</span></Link>
        }
    }

    if(isLoading){
        return (
            <div className="loadWrap loadWrapQuiz">
                <div className="loadWrapLogo">
                    <div className="loadWrapText">
                        <img width={16} src={LoadingGif} alt=""/>
                        Loading..
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="quizQuestion2">
                <div className="quizTextbl">
                    <p>Having a to-do list makes it easier to organize everything that you want to accomplish in the day so that you can start fresh the next.</p>
                    <p className="big">
                        According to statistics people who set tasks complete them <span>34% faster</span>
                    </p>
                </div>
                {linkElem('Continue')}
            </div>
        </>     
    )
}

export default memo(QuizQuestion4);
