import './styles.scss';
import React, {memo, useState} from 'react';

import {Link} from "gatsby";

import {
    LoadingGif,
    Dial1,
    Dial2,
    Dial3,
    Dial4,
} from "../../images";

type Props = {
    id: number,
    subtitle: string,
    arrLength: number,
    lastLink: string
}

const QuizQuestion3 = ({id, subtitle, arrLength, lastLink}: Props) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    let nextStep = +id + 1;

    const linkElem = (text) => {
        if(arrLength !== id){
            return <Link to={`/quiz-career/${nextStep}`} className="quizNext"><span>{text}</span></Link>
        } else{
            return <Link to={`${lastLink}`} className="quizNext"><span>{text}</span></Link>
        }
    }

    if(isLoading){
        return (
            <div className="loadWrap loadWrapQuiz">
                <div className="loadWrapLogo">
                    <div className="loadWrapText">
                        <img width={16} src={LoadingGif} alt=""/>
                        Loading..
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="quizQuestion2">
                <p className="quizTitle2 small">{subtitle}</p>
                <div className="quizRounds">
                    <div className="quizRound">
                        <div className="quizCircle">
                            <img src={Dial1} alt="" />
                            <span>55%</span>
                        </div>
                        <p>of users have undergone educational programs</p>
                    </div>
                    <div className="quizRound">
                        <div className="quizCircle">
                            <img src={Dial2} alt="" />
                            <span>67%</span>
                        </div>
                        <p>of users started earning more</p>
                    </div>
                    <div className="quizRound">
                        <div className="quizCircle">
                            <img src={Dial3} alt="" />
                            <span>68%</span>
                        </div>
                        <p>of users reached their goals</p>
                    </div>
                    <div className="quizRound">
                        <div className="quizCircle">
                            <img src={Dial4} alt="" />
                            <span>79%</span>
                        </div>
                        <p>of users have gained new skills</p>
                    </div>
                </div>
                {linkElem('Continue')}
            </div>
        </>     
    )
}

export default memo(QuizQuestion3);
