import './styles.scss';
import React, {memo, useState} from 'react';

import {Link} from "gatsby";

import {
    LoadingGif,
    Star
} from "../../images";

type Props = {
    id: number,
    question: string,
    arrLength: number,
    lastLink: string
}

const QuizQuestion5 = ({id, question, arrLength, lastLink}: Props) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    let nextStep = +id + 1;

    const linkElem = (text) => {
        if(arrLength !== id){
            return <Link to={`/quiz-career/${nextStep}`} className="quizNext"><span>{text}</span></Link>
        } else{
            return <Link to={`${lastLink}`} className="quizNext"><span>{text}</span></Link>
        }
    }

    if(isLoading){
        return (
            <div className="loadWrap loadWrapQuiz">
                <div className="loadWrapLogo">
                    <div className="loadWrapText">
                        <img width={16} src={LoadingGif} alt=""/>
                        Loading..
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="quizQuestion2">
                <p className="quizTitle2 small">{question}</p>
                <div className="quizFeed">
                    <div className="quizFeedItem">
                        <div className="quizFeedHeading">
                            <div className="quizFeedStars">
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                            </div>
                            <div className="quizFeedName">Alex</div>
                        </div>
                        <p className="quizFeedText">Thanks to your program, for the first time in my life, I began to fulfill my tasks!  Thank you!</p>
                    </div>
                    <div className="quizFeedItem">
                        <div className="quizFeedHeading">
                            <div className="quizFeedStars">
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                            </div>
                            <div className="quizFeedName">Andy</div>
                        </div>
                        <p className="quizFeedText">My productivity is increased using AEER program. Recommend! </p>
                    </div>
                    <div className="quizFeedItem">
                        <div className="quizFeedHeading">
                            <div className="quizFeedStars">
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                            </div>
                            <div className="quizFeedName">Joy</div>
                        </div>
                        <p className="quizFeedText">Now I won't sleep until I plan my new day.  It has become a habit</p>
                    </div>
                    <div className="quizFeedItem">
                        <div className="quizFeedHeading">
                            <div className="quizFeedStars">
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                            </div>
                            <div className="quizFeedName">Robyn</div>
                        </div>
                        <p className="quizFeedText">It has become easier to live when the whole plan is visible and I move along it step by step</p>
                    </div>
                    <div className="quizFeedItem">
                        <div className="quizFeedHeading">
                            <div className="quizFeedStars">
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                                <img src={Star} alt="" />
                            </div>
                            <div className="quizFeedName">Amy</div>
                        </div>
                        <p className="quizFeedText">I now recommend this app to everyone at work.  Thank you. </p>
                    </div>
                </div>
                {linkElem('Continue')}
            </div>
        </>     
    )
}

export default memo(QuizQuestion5);
