import '../styles/global.scss';
import '../styles/pages/quiz-career.scss';

import {HeadFC} from "gatsby";

import React, {useEffect, useState} from 'react'
import QuizQuestion1 from '../components/QuizQuestion1';
import QuizQuestion2 from '../components/QuizQuestion2';
import QuizQuestion3 from '../components/QuizQuestion3';
import QuizQuestion4 from '../components/QuizQuestion4';
import QuizQuestion5 from '../components/QuizQuestion5';
import QuizQuestion6 from '../components/QuizQuestion6';
import QuizQuestion7 from '../components/QuizQuestion7';
import QuizFooter from '../components/QuizFooter';

import {
    BigLogoImage
} from "../images";
import useAnalytics from "../hooks/useAnalytics";

type Props = {
    id: number
}

const QuizCareer = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);
    const [questions, setQuestions] = useState([
        {
            id: 1,
            type: 'type1',
            subtitle: 'I’m interested in:',
            name: "Boost your productivity and career growth",
            answers: ['professional development', 'skills development', 'personal productivity'],
        },
        {
            id: 2,
            type: 'type2',
            subtitle: '',
            name: "Do you consider yourself productive?",
            answers: ['Yes', 'No', 'I wish I could'],
        },
        {
            id: 3,
            type: 'type3',
            subtitle: 'USING AEER:',
            name: "",
            answers: [],
        },
        {
            id: 4,
            type: 'type2',
            subtitle: '',
            name: "Do you set tasks for your day/week/year?",
            answers: ['Yes', 'No', 'Rarely'],
        },
        {
            id: 5,
            type: 'type4',
            subtitle: '',
            name: "",
            answers: [],
        },
        {
            id: 6,
            type: 'type2',
            subtitle: '',
            name: "Do you track your goals?",
            answers: ['Yes', 'No', 'Rarely'],
        },
        {
            id: 7,
            type: 'type5',
            subtitle: '',
            name: "Here is what our users write:",
            answers: [],
        },
        {
            id: 8,
            type: 'type2',
            subtitle: '',
            name: "Do you do self-education?",
            answers: ['Yes, permanently', 'Not enough', 'Rarely'],
        },
        {
            id: 9,
            type: 'type6',
            subtitle: '',
            name: "AEER mini-courses will allow you to study those areas of interest in the shortest possible time.",
            answers: [],
        },
        {
            id: 10,
            type: 'type2',
            subtitle: '',
            name: "What are the areas you want to develop?",
            answers: ['Creativity', 'Productivity', 'Psychology', 'Physiology'],
        },
        {
            id: 11,
            type: 'type2',
            subtitle: '',
            name: "Do you track your incomes and expenses?",
            answers: ['Yes', 'No', 'Would like to'],
        },
        {
            id: 12,
            type: 'type7',
            subtitle: '',
            name: "",
            answers: [],
        }
    ])

    return (
        <>
            <div className="quizBox">
                <div className="quizWrap">
                    <div className="quizMain">
                        <div className="quizLogo">
                            <img src={BigLogoImage} alt="" />
                        </div>
                        {questions.map(quesKey => {
                            if(quesKey.id == id){
                                switch(quesKey.type){
                                    case 'type1':
                                        return <QuizQuestion2
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            question={quesKey.name}
                                            subtitle={quesKey.subtitle}
                                            answers={quesKey.answers}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                    case 'type2':
                                        return <QuizQuestion1
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            question={quesKey.name}
                                            answers={quesKey.answers}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                    case 'type3':
                                        return <QuizQuestion3
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            subtitle={quesKey.subtitle}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                    case 'type4':
                                        return <QuizQuestion4
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                    case 'type5':
                                        return <QuizQuestion5
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            question={quesKey.name}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                    case 'type6':
                                        return <QuizQuestion6
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            question={quesKey.name}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                    case 'type7':
                                        return <QuizQuestion7
                                            key={quesKey.id}
                                            id={quesKey.id}
                                            question={quesKey.name}
                                            arrLength={questions.length}
                                            lastLink='/trial' />
                                    break;
                                }

                            } else{
                                return null;
                            }

                        })}
                    </div>
                    <QuizFooter />
                </div>
            </div>
        </>
    )
}

export default QuizCareer;

export const Head: HeadFC = () => (
    <>
        <title>Quiz - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
