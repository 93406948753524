import './styles.scss';
import React, {memo, useState} from 'react';

import {Link} from "gatsby";

import {
    LoadingGif,
    Mobile
} from "../../images";

type Props = {
    id: number,
    question: string,
    arrLength: number,
    lastLink: string
}

const QuizQuestion6 = ({id, question, arrLength, lastLink}: Props) => {

    const [isLoading, setLoading] = useState(true);

    setTimeout(function(){
        setLoading(false)
    }, 300)

    let nextStep = +id + 1;

    const linkElem = (text) => {
        if(arrLength !== id){
            return <Link to={`/quiz-career/${nextStep}`} className="quizNext2"><span>{text}</span></Link>
        } else{
            return <Link to={`${lastLink}`} className="quizNext2"><span>{text}</span></Link>
        }
    }

    if(isLoading){
        return (
            <div className="loadWrap loadWrapQuiz">
                <div className="loadWrapLogo">
                    <div className="loadWrapText">
                        <img width={16} src={LoadingGif} alt=""/>
                        Loading..
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="quizQuestion2">
                <p className="quizTitle2 small">{question}</p>
                <div className="quizMobile2">
                    <img src={Mobile} alt="" />
                </div>
                {linkElem('Continue')}
            </div>
        </>     
    )
}

export default memo(QuizQuestion6);
